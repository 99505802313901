/** Layout **/
.wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  main {
    flex: 1 0 auto;
  }
}

.m-t-1 {
  margin-top: 1rem !important;
}

.m-t-2 {
  margin-top: 2rem !important;
}