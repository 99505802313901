header {
    width: 100%;
    z-index: 150;
    background-color: $header-background-color;
    @include transition(box-shadow 0.3s ease);
  
    [theme=dark] & {
      background-color: $header-background-color-dark;
    }
  
    .logo {
      min-height: 1.5em;
      height: 1.5em;
      vertical-align: text-bottom;
    }
  
    .logo,
    .header-title-pre {
      padding-right: .25rem;
    }
  
    .header-title-post {
      padding-left: .25rem;
    }
  }
  
  .header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
  }
  
  .header-title {
    font-family: $header-title-font-family;
    font-weight: bold;
    margin-right: .5rem;
    min-width: 10%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    @include flex(10);
  }
  
  .menu .menu-item {
    position: relative;
  
    @media only screen and (max-width: 960px) {
      font-size: 1.2em;
    }
  }
  
  .language-select {
    position: absolute;
    opacity: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  
    &:hover {
      cursor: pointer;
    }
  }
  
  .search {
    position: relative;
  
    input {
      color: transparent;
      box-sizing: border-box;
      height: 2.5rem;
      width: 2.5rem;
      @include border-radius(.5rem);
      border: none;
      outline: none;
      background-color: $header-background-color;
      vertical-align: baseline !important;
      @include transition(width 0.3s ease);
  
      [theme=dark] & {
        background-color: $header-background-color-dark;
      }
    }
  
    @include placeholder(transparent);
  
    .search-button {
      margin: 0;
      position: absolute;
      left: auto;
      right: 1rem;
    }
  
    .search-toggle {
      left: .5rem;
      right: auto;
    }
  
    .search-loading {
      display: none;
    }
  
    .search-clear {
      display: none;
    }
  
    .open &,
    &.mobile {
      input {
        color: $global-font-color;
        background-color: $search-background-color;
        padding: 0 2rem 0 2rem;
      }
  
      [theme=dark] & {
        input {
          color: $global-font-color-dark;
          background-color: $search-background-color-dark;
        }
  
        @include placeholder($global-font-secondary-color-dark);
      }
  
      @include placeholder($global-font-secondary-color);
  
      .search-button {
        color: $global-font-secondary-color;
  
        [theme=dark] & {
          color: $global-font-secondary-color-dark;
        }
      }
  
      .search-clear:hover {
        color: #ff6b6b;
      }
  
      .search-toggle:hover {
        cursor: default;
      }
    }
  }
  
  .theme-switch i {
    @include transform(rotate(225deg));
  }
  
  #header-desktop {
    display: block;
    position: fixed;
    height: $header-height;
    line-height: $header-height;
  
    [header-desktop=normal] & {
      position: static;
    }
  
    .header-wrapper {
      padding: 0 2rem 0 calc(10vw + 100% - 100vw);
      backdrop-filter: blur(10px);
      -webkit-backdrop-filter: blur(10px);
  
      .header-title {
        font-size: $header-title-font-size;
      }
  
      .menu {
        overflow: hidden;
        white-space: nowrap;
  
        .menu-inner {
          float: right;
        }
  
        .menu-item {
          margin: 0 .5rem;
  
          &.delimiter {
            border-left: 1.5px solid $global-font-color;
  
            [theme=dark] & {
              border-left-color: $global-border-color-dark;
            }
          }
  
          &.language {
            margin-right: 0;
          }
  
          &.search {
            margin: 0 -.5rem 0 0;
          }
        }
  
        a.active {
          font-weight: 900;
          color: $header-hover-color;
  
          [theme=dark] & {
            color: $header-hover-color-dark;
          }
        }
      }
    }
  
    &.open .header-wrapper .menu .menu-item.search {
      margin: 0 .25rem 0 .5rem;
  
      input {
        width: 24rem;
      }
    }
  }
  
  #header-mobile {
    display: none;
    position: fixed;
    height: $header-height;
    line-height: $header-height;
    backdrop-filter: blur(8px);
    -webkit-backdrop-filter: blur(8px);
  
    [header-mobile=normal] & {
      position: static;
    }
  
    .header-container {
      padding: 0;
      margin: 0;
  
      .header-wrapper {
        padding: 0 1rem;
        font-size: 1.125rem;
        @include transition(margin-top 0.3s ease);
  
        .header-title {
          font-size: $header-title-font-size;
          max-width: 80%;
        }
  
        .menu-toggle {
          line-height: 4rem;
          cursor: pointer;
          @include transition(width 0.3s ease);
  
          span {
            display: block;
            background: $global-font-color;
            width: 1.5rem;
            height: 2px;
            @include border-radius(3px);
            @include transition(all 0.3s ease-in-out);
  
            [theme=dark] & {
              background: $global-font-color-dark;
            }
          }
  
          span:nth-child(1) {
            margin-bottom: .5rem;
          }
  
          span:nth-child(3) {
            margin-top: .5rem;
          }
  
          &.active {
            span:nth-child(1) {
              @include transform(rotate(45deg) translate(.4rem, .5rem));
            }
  
            span:nth-child(2) {
              opacity: 0
            }
  
            span:nth-child(3) {
              @include transform(rotate(-45deg) translate(.4rem, -.5rem));
            }
          }
        }
      }
  
      .menu {
        text-align: center;
        background: $header-background-color-mobile;
        border-top: 2px solid $global-border-color;
        display: none;
        padding-top: .5rem;
        @include box-shadow(0 .125rem .25rem rgba(0, 0, 0, .1));
  
        .search-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
          box-sizing: border-box;
          padding: ($header-height - 2.5rem) / 2 1rem;
          line-height: 2.5rem;
        }
  
        .search {
          flex-grow: 10;
  
          .algolia-autocomplete, input {
            width: 100%;
          }
        }
  
        .search-button {
          top: 0;
        }
  
        .search-cancel {
          display: none;
          margin-left: .75rem;
        }
  
        .menu-item {
          display: block;
          line-height: 2.5rem;
        }
  
        &.active {
          display: block;
        }
  
        [theme=dark] & {
          background: $header-background-color-mobile-dark;
          border-top-color: $global-border-color-dark;
        }
      }
    }
  
    &.open {
      .header-wrapper {
        margin-top: -$header-height;
      }
  
      .menu {
        padding-top: 0;
        border-top: none;
  
        .menu-item {
          display: none;
        }
  
        .search-cancel {
          display: inline;
        }
      }
    }
  }
  
  .search-dropdown {
    position: fixed;
    z-index: 200;
    top: $header-height;
    @include box-shadow(0 .125rem .25rem rgba(0, 0, 0, .1));
  
    &.desktop {
      right: 2rem;
      width: 30rem;
    }
  
    &.mobile {
      right: 0;
      width: $global-width;
    }
  
    .dropdown-menu {
      right: 0 !important;
      background-color: $global-background-color;
  
      [theme=dark] & {
        background-color: $global-background-color-dark;
      }
  
      .suggestions {
        overflow-y: auto;
        max-height: calc(100vh - #{$header-height});
  
        .suggestion {
          padding: .75rem 1rem;
  
          .suggestion-title {
            display: inline-block;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            font-size: 1rem;
            font-weight: bold;
            max-width: 75%;
          }
  
          .suggestion-date {
            font-size: .875rem;
            float: right;
            text-align: right;
            color: $global-font-secondary-color;
  
            [theme=dark] & {
              color: $global-font-secondary-color-dark;
            }
          }
  
          .suggestion-context {
            line-height: 1.25rem;
            @include box(vertical);
            -webkit-line-clamp: 2;
            overflow: hidden;
            text-overflow: ellipsis;
            @include overflow-wrap(break-word);
            color: $global-font-secondary-color;
  
            [theme=dark] & {
              color: $global-font-secondary-color-dark;
            }
          }
  
          em {
            font-style: normal;
            background-color: $selection-color;
  
            [theme=dark] & {
              background-color: $selection-color-dark;
            }
          }
  
          &.cursor {
            background: darken($code-background-color, 5%);
  
            [theme=dark] & {
              background: lighten($code-background-color-dark, 5%);
            }
          }
  
          &:hover {
            cursor: pointer;
          }
        }
      }
  
      .search-empty {
        padding: 1rem;
        text-align: center;
  
        .search-query {
          font-weight: bold;
  
          [theme=dark] & {
            color: #ddd;
          }
        }
      }
  
      .search-footer {
        padding: .5rem 1rem;
        float: right;
        font-size: .8rem;
        color: $global-font-secondary-color;
  
        [theme=dark] {
          color: $global-font-secondary-color-dark;
        }
  
        @include link(false, false);
  
        a {
          font-size: 1rem;
        }
      }
    }
  }